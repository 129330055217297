import React from 'react';
import logo from './logo.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
          <p>Introducing Player.lease: The Future of AI-Powered Gaming</p>
          <p>
              You can now sign up <strong>early preview</strong> below.
          </p>
          <p>
              <a
                  className="App-link"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSf_NT4DAuYb702YTlzXNn_m3V5gXdFLlwQm-yDPn94CgbXl8w/viewform"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                  Fill the request form
              </a>
          </p>
          <p>
              <a
                  className="App-link"
                  href="https://photos.app.goo.gl/3hvqsDJTtMzfQz738"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                  See an example video
              </a>
          </p>
      </header>

      <footer className="App-footer">
       <div>
         <p className="type-small margin-reset">Proudly built by Werewolf Club in London</p>
       </div>
        </footer>
    </div>
  );
}

export default App;
